import React, { useState, useRef } from "react";
import SubCategories from "./SubCategories";
import data from "./data.json";
import Switch from "@mui/material/Switch";
import ReactToPrint from "./ReactToPrint";

import "./Categories.scss";

const Categories = () => {
  const [category, setCategory] = useState([
    {
      name: "",
      id: "",
      subcategories: [
        {
          id: "",
          contents: [{ name: "", id: "", text: "", forms: [], mixforms: [] }],
        },
      ],
    },
  ]);

  const [subcategory, setSubcategory] = useState([
    {
      id: "",
      contents: [{ name: "", id: "", text: "", forms: [], mixforms: [] }],
    },
  ]);

  const [value, setValue] = React.useState("1");
  const subcategories = category[0].subcategories[0].contents;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };

  const SelectCategory = (id) => {
    const category = data.categories.filter((category) => id === category.id);
    setCategory(category);
    setSubcategory([
      {
        id: "",
        contents: [{ name: "", id: "", text: "", form: [], mixforms: [] }],
      },
    ]);
  };

  const SelectSubcategory = (id) => {
    const subcategory = subcategories.filter(
      (subcategory) => id === subcategory.id,
    );

    setSubcategory(subcategory);
    setValue("1");
    setOpen(true);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [job, SetJob] = useState(false);
  const OnToggleJob = () => {
    SetJob(!job);
    console.log(job);
  };

  return (
    <div className="calculator">
      <div className="paper">
        <div className="section1">
          간호사
          <Switch
            checked={job}
            onChange={OnToggleJob}
            inputProps={{ "aria-label": "controlled" }}
          />
          의사
        </div>

        <div className="section2">
          {data.categories.map((category) => (
            <button
              className="red"
              key={category.id}
              onClick={() => SelectCategory(category.id)}
            >
              {category.name}
            </button>
          ))}
        </div>

        <hr></hr>
        <div className="section3">
          <SubCategories
            category={category}
            subcategory={subcategory}
            subcategories={subcategories}
            SelectSubcategory={SelectSubcategory}
            value={value}
            handleChangeValue={handleChangeValue}
            open={open}
            handleClose={handleClose}
            job={job}
          ></SubCategories>
        </div>
      </div>
    </div>
  );
};

export default Categories;
