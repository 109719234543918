import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const SetMixForm = ({ subcategory, handleChangeMixForm, mixform }) => {
  return (
    <FormControl variant="standard" sx={{ m: 1, width: 200 }}>
      <InputLabel>{subcategory[0].mixname}</InputLabel>
      <Select value={mixform} onChange={handleChangeMixForm}>
        {subcategory[0].mixforms.map((mixform) => (
          <MenuItem key={mixform.id} value={mixform.dose}>
            {mixform.dose}ml
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SetMixForm;
