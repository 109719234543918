import React, { useRef } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./SubCategories.scss";
import Modal from "./Modal";
import "./calculation/Formula.scss";
import Print from "./Print";
import ReactToPrint from "./ReactToPrint";

export default function CustomizedDialogs({
  subcategories,
  subcategory,
  SelectSubcategory,
  category,
  value,
  handleChangeValue,
  open,
  handleClose,
  job,
}) {
  return (
    <div className="Subcategories">
      <div className="segment">
        {category[0].id
          ? subcategories.map((subcategory) => (
              <button
                className="unit"
                key={subcategory.id}
                onClick={() => SelectSubcategory(subcategory.id)}
                variant="outlined"
              >
                <div className="text">{subcategory.text}</div>
                <div className="name">({subcategory.name})</div>
              </button>
            ))
          : null}
      </div>
      <div></div>
      <Print
        subcategories={subcategories}
        subcategory={subcategory}
        SelectSubcategory={SelectSubcategory}
        category={category}
        value={value}
        handleChangeValue={handleChangeValue}
        open={open}
        handleClose={handleClose}
        job={job}
      ></Print>
    </div>
  );
}
