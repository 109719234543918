import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const SetPurpose = ({ subcategory, purpose, handleChangePurpose }) => {
  return (
    <FormControl variant="standard" sx={{ m: 1, width: 200 }}>
      <InputLabel>목적을 고르세요</InputLabel>
      <Select value={purpose} onChange={handleChangePurpose}>
        {subcategory[0].purposes
          .filter((purpose) => purpose.type === "BwContinous")
          .map((purpose) => (
            <MenuItem key={purpose.id} value={purpose}>
              {purpose.text}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SetPurpose;
