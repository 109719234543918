import React, { forwardRef, useRef } from "react";
import "./Formula.scss";

const SetResult = forwardRef(
  (
    {
      form,
      mixform,
      subcategory,
      DrugDense,
      RemoveAdd,
      ampleDose,
      speed,
      Result,
      weight,
    },
    ref,
  ) => {
    return (
      <div ref={ref}>
        {form && mixform && speed ? (
          <div>
            <hr></hr>

            <div className="Result">
              <div className="Name">{subcategory[0].name}</div>
              <br></br>
              <div>
                {weight ? (
                  <div>
                    *속도 = {speed}
                    {subcategory[0].scaleW} ({weight}kg)
                  </div>
                ) : (
                  <div>
                    *속도 = {speed}
                    {subcategory[0].scale}
                  </div>
                )}
              </div>
              <div>
                *약물농도 = {(form * 1000) / mixform}
                mcg/ml
              </div>

              <br></br>
              <div className="Arrow">
                {subcategory[0].mixname} {mixform}cc에서 {RemoveAdd()}ml 제거
              </div>
              <div className="Arrow">↓</div>

              <div className="Arrow">
                {subcategory[0].name} {form}
                {subcategory[0].unit}({RemoveAdd()}ml)와 믹스
              </div>
              {subcategory[0].name === "Propofol" && ampleDose ? null : (
                <div className="Arrow">( 즉{form / ampleDose} 앰플과 믹스)</div>
              )}
              <div className="Arrow">↓</div>

              <div className="Arrow">
                {Result()}
                cc/hr로 투여
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  },
);
export default SetResult;
