import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const SetBodyWeight = ({ weight, handleChangeWeight }) => {
  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: 200 },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="standard-basic"
        label="체중 kg"
        variant="standard"
        value={weight}
        type="number"
        onChange={handleChangeWeight}
        onWheel={(e) => e.target.blur()}
      />
    </Box>
  );
};

export default SetBodyWeight;
