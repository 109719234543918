import React, { useEffect } from "react";

import "./App.scss";
import Categories from "./components/Categories";
import Home from "./pages/Home";
import Calculator from "./pages/Calculator";
import Protocol from "./pages/Protocol";
import Ventilator from "./pages/Ventilator";
import Crrt from "./pages/Crrt";
import Rsi from "./pages/Rsi";
import Schedule from "./pages/Schedule";
import Board from "./pages/Board";

const App = () => {
  useEffect(() => {
    document.querySelector(".버튼1").addEventListener("click", function () {
      document.getElementById("슬라").style.transform = "translate(0vw)";
    });
    document.querySelector(".버튼2").addEventListener("click", function () {
      document.getElementById("슬라").style.transform = "translate(-100vw)";
    });
    document.querySelector(".버튼3").addEventListener("click", function () {
      document.getElementById("슬라").style.transform = "translate(-200vw)";
    });
    document.querySelector(".버튼4").addEventListener("click", function () {
      document.getElementById("슬라").style.transform = "translate(-300vw)";
    });
    document.querySelector(".버튼5").addEventListener("click", function () {
      document.getElementById("슬라").style.transform = "translate(-400vw)";
    });
    document.querySelector(".버튼6").addEventListener("click", function () {
      document.getElementById("슬라").style.transform = "translate(-500vw)";
    });
    document.querySelector(".버튼7").addEventListener("click", function () {
      document.getElementById("슬라").style.transform = "translate(-600vw)";
    });
    document.querySelector(".버튼8").addEventListener("click", function () {
      document.getElementById("슬라").style.transform = "translate(-700vw)";
    });
    document.querySelector(".버튼9").addEventListener("click", function () {
      document.getElementById("슬라").style.transform = "translate(-800vw)";
    });
    document.querySelector(".버튼10").addEventListener("click", function () {
      document.getElementById("슬라").style.transform = "translate(-900vw)";
    });
    document.querySelector(".버튼11").addEventListener("click", function () {
      document.getElementById("슬라").style.transform = "translate(-1000vw)";
    });
  }, []);
  return (
    <div className="body">
      <div className="slide" id="슬라">
        <div className="slide1">
          <Calculator />
        </div>
        <div className="slide2">
          <Protocol />
        </div>
        <div className="slide3">
          <Ventilator />
        </div>
        <div className="slide4">
          <Crrt />
        </div>
        <div className="slide5">
          <Rsi />
        </div>
        <div className="slide6">
          <Schedule />
        </div>
        <div className="slide7">
          <Board />
        </div>
        <div className="slide8">8 </div>
        <div className="slide9">9</div>
        <div className="slide10">10</div>
        <div className="slide11">11</div>
      </div>
      <div className="button_container">
        <button className="버튼1">약물계산기</button>
        <button className="버튼2">약속처방</button>
        <button className="버튼3">벤틸레이터</button>
        <button className="버튼4">CRRT</button>
        <button className="버튼5">RSI</button>
        <button className="버튼6">스케쥴</button>
        <button className="버튼7">인슐린</button>
        <button className="버튼8">약물책받침</button>
        <button className="버튼9">간호사키트</button>
        <button className="버튼10">안티책받침</button>
        <button className="버튼11">격리책받침</button>
      </div>
    </div>
  );
};

export default App;
