import React from "react";
import Categories from "../components/Categories";

const Calculator = () => {
  return (
    <div>
      <Categories></Categories>
    </div>
  );
};

export default Calculator;
