import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./Formula.scss";

const Bolus = ({ subcategory, job }) => {
  const [purpose, setPurpose] = useState("");
  const handleChangePurpose = (e) => {
    setPurpose(e.target.value);
    console.log(subcategory[0].usualDoseBolus);
  };

  return (
    <div className="CalculatorBox">
      <div className="Indications">
        {/* 진정요법의 목적 */}
        <FormControl variant="standard" sx={{ m: 1, width: 200 }}>
          <InputLabel>목적을 고르세요</InputLabel>
          <Select value={purpose} onChange={handleChangePurpose}>
            {subcategory[0].purposes
              .filter((purpose) => purpose.type === "Bolus")
              .map((purpose) => (
                <MenuItem key={purpose.id} value={purpose}>
                  {purpose.text}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      <div className="ResultBolus">
        {purpose && <p>{purpose.usage}</p>}
        <p>{purpose.caution}</p>
      </div>
    </div>
  );
};

export default Bolus;
