import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const SetDrugSpeed = ({ subcategory, handleChangeSpeed, weight }) => {
  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: 200 },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="standard-basic"
        label={
          weight
            ? subcategory[0].drugSpeedPlaceholder
            : subcategory[0].drugSpeedPlaceholderNoBw
        }
        variant="standard"
        type="number"
        onChange={handleChangeSpeed}
        onWheel={(e) => e.target.blur()}
        color="secondary"
      />
    </Box>
  );
};

export default SetDrugSpeed;
