import React, { useState, useRef, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./Formula.scss";
import SetForm from "./SetForm";
import SetMixForm from "./SetMixForm";
import SelectMixForm from "./SelectMixForm";
import SetDrugSpeed from "./SetDrugSpeed";
import SetResult from "./SetResult";
import ReactToPrint from "react-to-print";

const Continous = ({ subcategory, job }) => {
  const [form, setForm] = useState("");
  const [mixform, setMixform] = useState("");
  const [speed, setSpeed] = useState();
  const [ampleDose, setAmpleDose] = useState("");
  const [purpose, setPurpose] = useState("");
  const [weight, setWeight] = useState("");

  useEffect(() => {
    setWeight("");
  }, []);

  const handleChangeForm = (e) => {
    setForm(e.target.value);
  };
  const handleChangeMixForm = (e) => {
    setMixform(e.target.value);
  };
  const handleChangeSpeed = (e) => {
    setSpeed(e.target.value);
    if (form || mixform) {
    } else {
      setMixform(subcategory[0].mixforms[1].dose);
      setForm(subcategory[0].forms[0].dose);
      setAmpleDose(subcategory[0].forms[0].dose);
    }
  };

  const handleChangeAmpleDose = (e) => {
    setAmpleDose(e.target.value);
  };

  const handleChangePurpose = (e) => {
    setPurpose(e.target.value);
  };

  function Result() {
    const basicResult = (mixform * speed) / form;
    if (subcategory[0].subSort === 1) {
      // mcg/min
      return Math.round(((basicResult * 60) / 1000) * 10) / 10;
    } else if (subcategory[0].subSort === 2) {
      // mg or unit/min
      return Math.round(basicResult * 60 * 10) / 10;
    } else if (subcategory[0].subSort === 3) {
      // mg / hr
      return Math.round(basicResult * 10) / 10;
    } else if (subcategory[0].subSort === 4) {
      return Math.round(((basicResult * 60) / 1000) * 10) / 10;
    } else if (subcategory[0].subSort === 5) {
      return Math.round(((basicResult * 60) / 1000) * 10) / 10;
    }
  }

  function DrugDense() {
    const basicDrugDens = form / mixform;
    if (subcategory[0].drugSort === 1) {
      // mg/ml
      return basicDrugDens * 1000;
    } else if (subcategory[0].drugSort === 2) {
      // mcg/ml
      return basicDrugDens;
    } else if (subcategory[0].drugSort === 3) {
      // unit/ml
      return basicDrugDens;
    }
  }

  function RemoveAdd() {
    if (ampleDose == 15) {
      return (
        (subcategory[0].AmpleAmount * form * 0.2) / subcategory[0].dosePerAmple
      );
    } else
      return (subcategory[0].AmpleAmount * form) / subcategory[0].dosePerAmple;
  }
  const componentRef = useRef();

  return (
    <div className="CalculatorBox">
      {/* 진정요법의 목적을 */}
      <div className="Indications">
        {job && (
          <FormControl variant="standard" sx={{ m: 1, maxWidth: 300 }}>
            <InputLabel>목적을 고르세요</InputLabel>
            <Select value={purpose} onChange={handleChangePurpose}>
              {subcategory[0].purposes
                .filter((purpose) => purpose.type === "Continous")
                .map((purpose) => (
                  <MenuItem key={purpose.id} value={purpose}>
                    {purpose.text}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </div>
      <div className="Usage">
        {" "}
        {job ? (
          <p>{purpose.usage}</p>
        ) : (
          <p>{subcategory[0].usualDoseWithoutBw}</p>
        )}
      </div>

      {/* 약물 속도 입력창 */}
      <div className="DrugSpeed">
        <SetDrugSpeed
          weight={weight}
          subcategory={subcategory}
          handleChangeSpeed={handleChangeSpeed}
        ></SetDrugSpeed>
      </div>

      {/* 믹스할 약물 용량 */}
      {speed && (
        <div className="DrugAmount">
          <SetForm
            form={form}
            subcategory={subcategory}
            handleChangeForm={handleChangeForm}
          ></SetForm>
        </div>
      )}

      {/* 믹스할 수액 용량 */}
      {speed && (
        <div className="FluidAmount">
          <SetMixForm
            mixform={mixform}
            subcategory={subcategory}
            handleChangeMixForm={handleChangeMixForm}
          ></SetMixForm>
        </div>
      )}

      {/* 원내 구비된 약물 선택 */}
      {speed && (
        <div className="DrugSort">
          <SelectMixForm
            subcategory={subcategory}
            ampleDose={ampleDose}
            handleChangeAmpleDose={handleChangeAmpleDose}
          ></SelectMixForm>
        </div>
      )}
      {/* 약물 조제법 */}
      <SetResult
        form={form}
        mixform={mixform}
        subcategory={subcategory}
        DrugDense={DrugDense}
        RemoveAdd={RemoveAdd}
        ampleDose={ampleDose}
        Result={Result}
        speed={speed}
        ref={componentRef}
      ></SetResult>
      {speed && (
        <ReactToPrint
          trigger={() => <button>프린트하기</button>}
          content={() => componentRef.current}
        />
      )}
      <div>
        <p>{purpose.caution}</p>
      </div>
    </div>
  );
};

export default Continous;
