import React, { useState, useRef } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ReactToPrint from "react-to-print";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import "./Formula.scss";

const Premix = ({ subcategory, job }) => {
  const [form, setForm] = useState("");
  const [mixform, setMixform] = useState("");
  const [speed, setSpeed] = useState();
  const [ampleDose, setAmpleDose] = useState("");
  const [weight, setWeight] = useState("");
  const [purpose, setPurpose] = useState("");

  const handleChangeForm = (e) => {
    setForm(e.target.value);
  };
  const handleChangeMixForm = (e) => {
    setMixform(e.target.value);
  };
  const handleChangeSpeed = (e) => {
    setSpeed(e.target.value);
    setForm(subcategory[0].forms[1].form);
    setMixform(subcategory[0].forms[1].mixform);
    // if (form || mixform) {
    // } else {
    //   setForm(400);
    //   setMixform(500);
    // }
  };
  const handleChangeWeight = (e) => {
    setWeight(e.target.value);
  };

  const handleChangeAmpleDose = (e) => {
    setAmpleDose(e.target.value);
  };

  const handleChangePurpose = (e) => {
    setPurpose(e.target.value);
  };

  function Result() {
    const basicResult = (mixform * weight * speed) / form;
    const preFormResult = (ampleDose.mixform * weight * speed) / ampleDose.form;
    // subSort1 (mcg/kg/min)
    // subSort1 (mcg/kg/min)

    if (ampleDose.premix === true) {
      return Math.round(((preFormResult * 60) / 1000) * 10) / 10;
    } else if (ampleDose.premix === false) {
      return Math.round(((basicResult * 60) / 1000) * 10) / 10;
    }
  }
  const componentRef = useRef();

  return (
    <div className="CalculatorBox">
      {/* 진정요법의 목적 */}
      <div className="Indications">
        {job && (
          <FormControl variant="standard" sx={{ m: 1, width: 200 }}>
            <InputLabel>
              어떤 상황에서 사용하시나요? 용법을 알려드려요.
            </InputLabel>
            <Select value={purpose} onChange={handleChangePurpose}>
              {subcategory[0].purposes
                .filter((purpose) => purpose.type === "BwContinous")
                .map((purpose) => (
                  <MenuItem key={purpose.id} value={purpose}>
                    {purpose.text}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </div>
      {/* 진정요법의목적 여기까지 */}
      {/* 일반용량 */}
      <div className="Usage">
        {" "}
        {job ? (
          <p>{purpose.usage}</p>
        ) : (
          <div>
            <p>{subcategory[0].usualDoseWithBw}</p>
          </div>
        )}
      </div>
      {/* ====일반용량여기까지 */}
      {/* 약물 속도 입력창 */}
      <div className="DrugSpeed">
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: 200 },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="standard-basic"
            label={subcategory[0].drugSpeedPlaceholder}
            variant="standard"
            type="number"
            onChange={handleChangeSpeed}
            onWheel={(e) => e.target.blur()}
            color="secondary"
          />
        </Box>
      </div>
      {/* =======여기까지 약물속도입력창 */}
      {/* 체중입력창 */}
      {speed && (
        <div className="BodyWeight">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: 200 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="standard-basic"
              label="체중 kg을 입력하세요."
              variant="standard"
              value={weight}
              type="number"
              onChange={handleChangeWeight}
              onWheel={(e) => e.target.blur()}
            />
          </Box>
        </div>
      )}
      {/* 끝 체중입력창 */}

      {/* 병원에 있는 수액제제 입력창 */}
      {weight && (
        <div className="FluidAmount">
          <FormControl variant="standard" sx={{ m: 1, width: 200 }}>
            <InputLabel>
              *병원에 있는 {subcategory[0].text}의 종류를 고르세요.
            </InputLabel>
            <Select value={ampleDose} onChange={handleChangeAmpleDose}>
              {subcategory[0].forms.map((drug) => (
                <MenuItem key={drug.id} value={drug}>
                  {drug.ample}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      {/* 결과창 */}
      <div>
        {ampleDose.premix ? (
          <p>
            {Result()}
            cc/hr로 투여
          </p>
        ) : // {/* 믹스할 약물 용량 */}
        speed && weight && ampleDose ? (
          <div>
            <div className="DrugAmount">
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: 200 },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="standard-basic"
                  label={subcategory[0].formPlaceholder}
                  variant="standard"
                  value={form}
                  type="number"
                  onChange={handleChangeForm}
                  onWheel={(e) => e.target.blur()}
                />
              </Box>
            </div>
            <div className="BodyWeight">
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: 200 },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="standard-basic"
                  label="믹스할 수액의 용량을 입력하세요(ml) "
                  variant="standard"
                  value={mixform}
                  type="number"
                  onChange={handleChangeMixForm}
                  onWheel={(e) => e.target.blur()}
                />
              </Box>
            </div>
            <div ref={componentRef}>
              <div className="Result">
                <div className="Name">{subcategory[0].name}</div>
                <div>
                  *속도 = {speed} {subcategory[0].scale} ({weight}kg)로 투여시
                </div>
                <div>
                  *약물농도 = {(form * 1000) / mixform}
                  mcg/ml
                </div>

                <br></br>
                <div className="Arrow">
                  {subcategory[0].mixname} {mixform}cc에서{" "}
                  {(form / ampleDose.dose) * 5}
                  ml 제거
                </div>
                <div className="Arrow">↓</div>

                <div className="Arrow">
                  {subcategory[0].name} {form}
                  {subcategory[0].unit}({(form / ampleDose.dose) * 5} ml)과 믹스
                </div>
                {ampleDose && (
                  <div className="Arrow">
                    ( 즉{form / ampleDose.dose} 앰플과 믹스)
                  </div>
                )}
                <div className="Arrow">↓</div>

                <div className="Arrow">
                  {Result()}
                  cc/hr로 투여
                </div>
              </div>
            </div>
            {speed && (
              <ReactToPrint
                trigger={() => <button>프린트하기</button>}
                content={() => componentRef.current}
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Premix;
