import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./Formula.scss";

const BwBolus = ({ subcategory, job }) => {
  const [purpose, setPurpose] = useState("");
  const [weight, setWeight] = useState("");
  const [form, setForm] = useState("");

  const handleChangePurpose = (e) => {
    setPurpose(e.target.value);
    // setForm(purpose.dose);
    setWeight(60);
    // console.log(purpose);
  };

  const handleChangeForm = (e) => {
    setForm(e.target.value);
  };

  const handleChangeWeight = (e) => {
    setWeight(e.target.value);
  };

  return (
    <div className="CalculatorBox">
      {/* 진정요법의 목적을 */}
      {job && (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
          <InputLabel>뭐 때문에 사용하세요?</InputLabel>
          <Select value={purpose} onChange={handleChangePurpose}>
            {subcategory[0].purposes
              .filter((purpose) => purpose.type === "BwBolus")
              .map((purpose) => (
                <MenuItem key={purpose.id} value={purpose}>
                  {purpose.text}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      <div className="IndicationsBolus">
        {" "}
        {job ? (
          <p>{purpose.usage}</p>
        ) : (
          <p>{subcategory[0].usualDoseWithBwBolus}</p>
        )}
      </div>
      {/* 약물 속도 입력창 */}
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: 200 },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="standard-basic"
          label={subcategory[0].bodyWeightPerAmountPlaceholder}
          variant="standard"
          onChange={handleChangeForm}
          color="secondary"
          onWheel={(e) => e.target.blur()}
        />
      </Box>
      {/* 체중입력창 */}
      {form && (
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: 200 },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="standard-basic"
            label="체중 kg을 입력하세요"
            variant="standard"
            value={weight}
            type="number"
            onChange={handleChangeWeight}
            onWheel={(e) => e.target.blur()}
          />
        </Box>
      )}

      {form && (
        <div>
          <div className="ResultBolus">
            <p>
              {weight * form}
              {subcategory[0].unit}투여합니당
            </p>
          </div>
          <div>
            <p>{purpose.caution}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BwBolus;
