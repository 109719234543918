import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const SetForm = ({ subcategory, handleChangeForm, form }) => {
  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: 200 },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="standard-basic"
        label={subcategory[0].formPlaceholder}
        variant="standard"
        value={form}
        type="number"
        onChange={handleChangeForm}
        onWheel={(e) => e.target.blur()}
      />
    </Box>
  );
};

export default SetForm;
