import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import BwBolus from "../calculation/BwBolus";
import Bolus from "../calculation/Bolus";
import Continous from "../calculation/Continous";
import BwContinous from "../calculation/BwContinous";
import Premix from "../calculation/Premix";

const CommonTab = ({ subcategory, handleChangeValue, value, job }) => {
  const newList = subcategory[0].Tablist.filter((list) => list.done === true);
  const result = newList.map((item, index) => ({
    item: item,
    id: index + 1,
  }));
  console.log(result);

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed;
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <div>
      <Box
        sx={{
          maxWidth: 300,
          typography: "body1",
        }}
        overflow="hidden"
      >
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              maxWidth: { xs: 300, sm: 480 },
            }}
          >
            <TabList
              onChange={handleChangeValue}
              aria-label="lab API tabs example"
              sx={{ width: 300 }}
              variant="scrollable"
              scrollButtons={false}
            >
              {result.map((i) => (
                <Tab
                  label={i.item.scale}
                  value={i.id.toString()}
                  key={i.id}
                  sx={{
                    textTransform: "none",
                    maxWidth: 30,
                  }}
                />
              ))}
            </TabList>
          </Box>

          {result
            .filter((i) => i.item.label === "Bolus")
            .map((i) => (
              <TabPanel value={i.id.toString()} key={i.id}>
                <Bolus subcategory={subcategory} job={job}></Bolus>
              </TabPanel>
            ))}
          {result
            .filter((i) => i.item.label === "BwBolus")
            .map((i) => (
              <TabPanel value={i.id.toString()} key={i.id}>
                <BwBolus subcategory={subcategory} job={job}></BwBolus>
              </TabPanel>
            ))}
          {result
            .filter((i) => i.item.label === "Continous")
            .map((i) => (
              <TabPanel value={i.id.toString()} key={i.id}>
                <Continous subcategory={subcategory} job={job}></Continous>
              </TabPanel>
            ))}
          {result
            .filter((i) => i.item.label === "BwContinous")
            .map((i) => (
              <TabPanel value={i.id.toString()} key={i.id}>
                <BwContinous subcategory={subcategory} job={job}></BwContinous>
              </TabPanel>
            ))}
          {result
            .filter((i) => i.item.label === "Premix")
            .map((i) => (
              <TabPanel value={i.id.toString()} key={i.id}>
                <Premix subcategory={subcategory} job={job}></Premix>
              </TabPanel>
            ))}
        </TabContext>
      </Box>
    </div>
  );
};

export default CommonTab;
