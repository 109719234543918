import React from "react";
import CommonTab from "./tablist/CommonTab";

const Modal = ({ subcategory, value, handleChangeValue, job }) => {
  return (
    <div>
      <CommonTab
        subcategory={subcategory}
        value={value}
        handleChangeValue={handleChangeValue}
        job={job}
      />
    </div>
  );
};

export default Modal;
