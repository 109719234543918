import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

const SelectMixForm = ({ subcategory, handleChangeAmpleDose, ampleDose }) => {
  const [selectedValue, setSelectedValue] = React.useState("a");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  return (
    <FormControl>
      <FormLabel sx={{ fontSize: 10 }}>
        *병원에 있는 {subcategory[0].text}의 종류를 고르세요.
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={ampleDose}
        onChange={handleChangeAmpleDose}
      >
        {subcategory[0].forms.map((drug) => (
          <FormControlLabel
            sx={{ fontSize: 12, margin: 0 }}
            value={drug.dose}
            key={drug.id}
            disableTypography
            control={<Radio sx={{ fontSize: 6 }} size="small" />}
            label={drug.ample}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default SelectMixForm;
